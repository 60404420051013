/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://c2tnwb3zm5gghkaupnwvot5o64.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:3eb1b99f-c3ec-4d76-8d0d-3d019f9c1b93",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_d98GF00xB",
    "aws_user_pools_web_client_id": "7ob4b137cqk2g47oqk9rn0d8pu",
    "oauth": {},
};


export default awsmobile;
